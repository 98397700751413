<template>
    <div class="container xl">
        <div class="main-cols">
            <div class="left-menu-col">
                <LeftMenu />
            </div>
            <div class="right-content-col">
                <div class="loading-overlay" v-if="loading">
                    <div class="loader"></div>
                </div>

                <div class="confirmation-message" v-if="!loading">
                    <h2 class="title">
                        <span>Thank You</span>
                    </h2>
                    <p>We have received your order.</p>
                </div>

                <OpenOrder
                    :order="transaction.order"
                    v-if="transaction.order"
                />

                <!-- <div class="next-step">
          <div class="step">
            <div class="title">What Next?</div>
            <p>We'll collect your parcel in 2 days</p>
          </div>
          <div class="date-time">
            <div class="date">March 26, 2021</div>
            <div class="time">11:00 AM</div>
          </div>
          <div class="action">
            <a href="#" class="add-to-calendar">Add date to calendar</a>
          </div>
        </div> -->

                <div class="additional-links">
                    <router-link to="/orders">View your orders</router-link>
                    <a href="#" class="quote-n-book-trigger"
                        >Send another package</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* global gtag */

import LeftMenu from "@/components/LeftMenu";
import OpenOrder from "@/components/orders/OpenOrder";

export default {
    name: "OrdersConfirmationView",
    data() {
        return {
            loading: false,
            transaction: {
                order: null,
            },
        };
    },
    components: {
        LeftMenu,
        OpenOrder,
    },
    mounted() {
        this.loading = true;
        let query = this.$route.query;
        if (query.transactionId) {
            this.$store
                .dispatch("fetchTransaction", {
                    transactionId: query.transactionId,
                })
                .then((data) => {
                    this.transaction = data.transaction;
                    this.loading = false;

                    //Event snippet for Purchase (1) conversion page
                    gtag("event", "conversion", {
                        send_to: process.env.VUE_APP_GOOGLE_ADS_PURCHASE_ID,
                        value: this.transaction.amount,
                        currency: "AED",
                        transaction_id: "",
                    });
                });
        }

        if (document.querySelector(".quote-n-book-trigger")) {
            document
                .querySelector(".quote-n-book-trigger")
                .addEventListener("click", (e) => {
                    e.preventDefault();
                    let quoteNBookWidget = document.querySelector(
                        ".quote-n-book-widget"
                    );
                    if (!quoteNBookWidget.classList.contains("expand")) {
                        quoteNBookWidget.classList.add("expand");
                    }
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                });
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/partials/order-result-view.scss";
</style>
