<template>
  <div class="transaction">
    <div class="left">
      <div class="info">
        <div class="caption">Transcation ID:</div>
        <div class="value">{{ transaction.transId }}</div>
      </div>

      <div class="info">
        <div class="caption">Date:</div>
        <div class="value">{{ formattedDate.monthDate }}{{ ',  ' + formattedDate.year }}</div>
      </div>

      <div class="info">
        <div class="caption">Amount:</div>
        <div class="value">{{ transaction.amount }} AED</div>
      </div>
    </div>
    <div class="right">
      <div class="courier-company">
        <div class="logo" :style="{ 'background-image': transaction.order.selectedCompany.logoImage != null ? 'url(' + transaction.order.selectedCompany.logoImage + ')' : '' }"></div>
        <div class="tracking-number">{{ transaction.order.selectedCompany.name }}</div>
      </div>

      <router-link :to="{ path: '/order/' + transaction.order.id }" class="btn show-order">Show Order</router-link>
    </div>
  </div>
</template>

<script>
import flatpickr from 'flatpickr';

export default {
  name: 'Transaction',
  props: ['transaction'],
  data() {
    return {
      formattedDate: {
        monthDate: null,
        year: null,
      },
    };
  },
  mounted() {
    let date = flatpickr.parseDate(this.transaction.createdDate, 'd-M-y h:i K');
    this.formattedDate.monthDate = flatpickr.formatDate(date, 'F j');
    this.formattedDate.year = flatpickr.formatDate(date, 'Y');
  },
};
</script>

<style></style>
