<template>
  <div class="loading-overlay" v-if="loading">
    <div class="loader"></div>
  </div>
  <div class="transactions" v-if="!loading">
    <div v-if="$store.state.transactions" class="transactions-list">
      <Transaction v-for="(transaction, key) in $store.state.transactions" :key="key" :transaction="transaction" />
    </div>
    <div v-if="$store.state.transactions && !$store.state.transactions.length" class="no-content-message">
      <p>No transactions yet.</p>
    </div>
  </div>
</template>

<script>
import Transaction from '@/components/transactions/Transaction';

export default {
  name: 'TransactionsList',
  components: {
    Transaction,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.loading = true;

    this.$store.dispatch('fetchTransactions').then(() => {
      this.loading = false;
    });

    /* let collectionDate = flatpickr.parseDate(this.order.collectionDate, 'Y-m-d');
    this.formattedDate.monthDate = flatpickr.formatDate(collectionDate, 'F j');
    this.formattedDate.year = flatpickr.formatDate(collectionDate, 'Y'); */
  },
};
</script>

<style lang="scss">
@import '../../scss/partials/variables.scss';

.transaction {
  padding: 25px;
  background-color: #fff;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.02);
  justify-content: space-between;
  margin-bottom: 20px;

  .right {
    text-align: right;
  }

  .info {
    display: flex;
    margin-bottom: 14px;

    .caption {
      font-size: 16px;
      color: #7c7b7b;
      margin-right: 10px;
    }

    .value {
      color: #252423;
      font-size: 16px;
      font-weight: 500;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .tracking-number {
    font-size: 14px;
  }

  .btn {
    padding: 11px 22px;
    border-radius: 18px;
    box-shadow: 0 2px 20px 0 rgba(148, 102, 0, 0.47);
    background-color: #e79f00;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    text-transform: uppercase;
  }

  @media (max-width: 640px) {
    display: block;

    .left {
      margin-bottom: 18px;
    }

    .right {
      text-align: left;
    }

    .tracking-number {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
