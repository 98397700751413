<template>
  <div class="container xl">
    <div class="main-cols">
      <div class="left-menu-col">
        <LeftMenu />
      </div>
      <div class="right-content-col">
        <TransactionsList />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu';
import TransactionsList from '@/components/transactions/TransactionsList';

export default {
  name: 'TransactionsView',
  components: {
    LeftMenu,
    TransactionsList,
  },
};
</script>

